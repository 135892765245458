<template>
  <div class="categories">
    <Navbar/>  

    <div class="container">

        <SubNav>
            <template #current>
                Выбрать город
            </template>
        </SubNav>
        <div class="row">
            <div class="col-12 h1-wrapper">
                <h1>Пожалуйста, выберите город</h1>
            </div>
        </div>

        <div class="row school-row">
            <div class="col-lg-6 school-wrapper">
                <div class="image-wrapper image-paris h-100">
                    <div class="informations">
                        <div class="d-flex flex-column justify-content-center h-100">
                            <div class="title">
                                <router-link class="city-link" to="/cities/paris">
                                    <h2>Париж</h2>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-6 school-wrapper">
                <div class="image-wrapper image-montpellier h-100">
                    <div class="informations">
                        <div class="d-flex flex-column justify-content-center h-100">
                            <div class="title">
                                <router-link class="city-link" to="/cities/montpellier">
                                    <h2>Монтпелье</h2>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row school-row" >
            <div class="col-lg-6 school-wrapper">
                <div class="image-wrapper image-reims h-100">
                    <div class="informations">
                        <div class="d-flex flex-column justify-content-center h-100">
                            <div class="title">
                                <router-link class="city-link" to="/cities/reims">
                                    <h2>Реймс</h2>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 school-wrapper">
                <div class="image-wrapper image-lille h-100">
                    <div class="informations">
                        <div class="d-flex flex-column justify-content-center h-100">
                            <div class="title">
                                <router-link class="city-link" to="/cities/lille">
                                    <h2>Лилль</h2>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import SubNav from '@/components/common/slots/SubNavGeneral.vue'

export default {
  name: 'BusinessSchools',
  components: {
    Navbar,
    SubNav
  }
}
</script>
